import _ from 'lodash';
import './../sass/main.scss';

window.$ = window.jQuery = require('jquery');
require('jquery-migrate');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

require ('./typographer/jquery.typographer.min.pack.js');
require ('./typographer/hyph-pl.min.js');

require('slick-carousel');

require ('../../node_modules/slicknav/jquery.slicknav.js');

require('../plugins/pretty_photo/js/jquery.prettyPhoto.js');

jQuery.noConflict();



jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("wheel", handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};



jQuery(document).ready(function(){
	
	jQuery('body img').addClass('img-fluid');
	
	jQuery('body').typographer_orphan({
	  forbidden: ["a", "i", "o", "u", "w", "z", "oraz", "dla"]
	});
	
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
	  return new bootstrap.Popover(popoverTriggerEl)
	});

	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	});
	
	//Sticky START
	
	//var stickyNavTop = $('.nav').offset().top;
	var stickyNavTop = 140;
		   	
	// our function that decides weather the navigation bar should have "fixed" css position or not.
	var stickyNav = function(){
		var mainScrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (mainScrollTop > stickyNavTop) { 
			jQuery('#main-top-bar').addClass('main-nav-top-sticky');
		} else {
			jQuery('#main-top-bar').removeClass('main-nav-top-sticky');
		}
	};

	// and run it again every time you scroll
	jQuery(window).scroll(function() {
		stickyNav();
	});

	if(jQuery('#wpadminbar').is(":visible")) {
		jQuery('#main-top-bar').addClass('main-wp-bar');
	} else jQuery('#main-top-bar').removeClass('main-wp-bar');
	
	//Sticky STOP
	
	//Slider Header START

	if (typeof isMainHome !== 'undefined') {

		var hdCarousel = document.querySelector('#slider-header');
		var headerCarousel = new bootstrap.Carousel(hdCarousel, {
			pause: true,
		});

		jQuery('#slider-header .carousel-caption .main-after').click(function() {
			headerCarousel.next();
		});

	}
	
	//Slider Header STOP
	
	//SlickCarousel a START
	
	jQuery('.carousel-type-a, .carousel-type-b').slick({
		autoplay: false,
		arrows: true,
		dots: true,
		slidesToShow: 2,
  		slidesToScroll: 2,
		responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
	});
	
	//SlickCarousel a STOP
	
	jQuery("#main-menu-top > ul").slicknav({
		label: '',
		closeOnClick: true
	});
	jQuery('.slicknav_menu').prepend('<a href="' + mainRootUrl + '" id="mob-logo"><img src="' + mainThemeUrl + '/assets/media_and_docs/logo.png" width="171" height="20" alt="Obrazek"></a>');

	//PrettyPhoto START

	jQuery('.gallery .gallery-icon > a').attr('rel', 'prettyPhoto');

	var refJQ;
	jQuery('.gallery').each(function (index) {
		refJQ = jQuery(this);
		refJQ.find('.gallery-icon > a').attr('rel', 'prettyPhoto[' + refJQ.attr('id') + ']');
	});

	var refJQ2; var giDesc;
	jQuery('.gallery .gallery-item').each(function (index) {
		refJQ2 = jQuery(this);
		giDesc = '<h2 class="gallery-it-title">' + refJQ2.find('.gallery-icon > a > img').attr('alt') + '</h2>';
		jQuery(giDesc).insertAfter(refJQ2.find('.gallery-icon'));
	});

	jQuery('.gallery.gallery-columns-1 .gallery-item').wrapAll( "<div class='row' />");
	jQuery('.gallery.gallery-columns-1 .row .gallery-item').wrap( "<div class='col-md-1' />");
	jQuery('.gallery.gallery-columns-2 .gallery-item').wrapAll( "<div class='row' />");
	jQuery('.gallery.gallery-columns-2 .row .gallery-item').wrap( "<div class='col-md-6' />");
	jQuery('.gallery.gallery-columns-3 .gallery-item').wrapAll( "<div class='row' />");
	jQuery('.gallery.gallery-columns-3 .row .gallery-item').wrap( "<div class='col-md-4' />");
	jQuery('.gallery.gallery-columns-4 .gallery-item').wrapAll( "<div class='row' />");
	jQuery('.gallery.gallery-columns-4 .row .gallery-item').wrap( "<div class='col-md-3' />");
	jQuery('.gallery.gallery-columns-6 .gallery-item').wrapAll( "<div class='row' />");
	jQuery('.gallery.gallery-columns-6 .row .gallery-item').wrap( "<div class='col-md-2' />");

	jQuery("a[rel^='prettyPhoto']").prettyPhoto();

	//PrettyPhoto STOP

	//Contact Form 7 START

	jQuery('#main-div-body .col-contact-section .wpcf7-form').addClass('form-contact form-contact-light');
	jQuery('#main-div-body .col-contact-section .wpcf7-form > .row > div').each(function (index) {
		var $thisP = jQuery(this);
		$thisP.find('input').removeAttr('size').addClass('form-control');
		$thisP.find('textarea').removeAttr('size').removeAttr('cols').removeAttr('rows').addClass('form-control');
	});

	jQuery('footer .wpcf7-form').addClass('form-contact');
	jQuery('footer .wpcf7-form > .row > div').each(function (index) {
		var $thisP = jQuery(this);
		$thisP.find('input').removeAttr('size').addClass('form-control');
		$thisP.find('textarea').removeAttr('size').removeAttr('cols').removeAttr('rows').addClass('form-control');
	});

	if(jQuery('.form-contact').length > 0) {
		jQuery('.form-contact .main-btn-one').unwrap('p');
		jQuery('.form-contact .main-btn-one').each(function() {
			let thisBtn = jQuery(this);
			thisBtn.prepend(thisBtn.siblings('.btn-decor').first());
			thisBtn.prepend(thisBtn.siblings('.btn-title').first());
		});
		jQuery('.form-contact label').unwrap('p');
	}

	//Contact Form 7 STOP

	mainResize();
	
});

jQuery(window).resize(function() {
	mainResize();
});

window.onload = function () {
	jQuery('#main-loader').remove();
	//Scroll START
	/*
	var posTopMainDivBody = 0;
	if (typeof isMainHome === 'undefined') {
		setTimeout(function () {
			posTopMainDivBody = jQuery('#main-div-body').position().top;
			jQuery('html, body').animate({scrollTop: (posTopMainDivBody - 60)}, 'slow');
		}, 200);
	}
	 */
	//Scroll STOP
};


//Funkcje

function mainResize() {
	var wwidth = jQuery(window).width();
    if (wwidth <= 767) {
		placementObjectsAfter('#element-src-1', '#element-trg-after-1');
		jQuery('#img-abs-1').attr('src', '');
		jQuery('#img-abs-2').attr('src', '');
		jQuery('#slider-header').hide();
		jQuery('#slider-header .img-slide').attr('src', '');
		jQuery('#header-intro').show();
	} else {
		placementObjectsAppend('#element-src-1', '#container-src-1');
		jQuery('#img-abs-1').attr('src', jQuery('#img-abs-1').attr('data-src-tg'));
		jQuery('#img-abs-2').attr('src', jQuery('#img-abs-2').attr('data-src-tg'));
		jQuery('#slider-header .img-slide').each(function () {
			var slhThis = jQuery(this);
			slhThis.attr('src', slhThis.attr('data-src-tg'));
		});
		jQuery('#slider-header').show();
		if (typeof isMainHome !== 'undefined') jQuery('#header-intro').hide();
	}
	if (typeof isMainHome !== 'undefined') {
		setHeaderHeight();
		setSliderTopHeight();
	} else {
		setHeaderHeight(0.312);
		setHeaderIntroHeight(0.312);
	}
}

function placementObjectsAfter(element, elementAfter) {
	jQuery(elementAfter).after(jQuery(element));
}

function placementObjectsAppend(element, elementAppend) {
	jQuery(elementAppend).append(jQuery(element));
}

function placementObjectsPrepend(element, elementPrepend) {
	jQuery(elementPrepend).prepend(jQuery(element));
}

function setHeaderHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#main-header').height(headerWidth*.56);
	else jQuery('#main-header').height(headerWidth*factor);
}

function setSliderTopHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#slider-header').height(headerWidth*.56);
	else jQuery('#slider-header').height(headerWidth*factor);
}

function setHeaderIntroHeight(factor = 0) {
	var headerWidth = jQuery('#main-header').width();
	if(!factor) jQuery('#header-intro').height(headerWidth*.56);
	else jQuery('#header-intro').height(headerWidth*factor);
}